import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ITextFilterParams,
  SideBarDef,
} from 'ag-grid-community';
import {
  LedgerContactsService,
  Contact,
  SearchLedgerContactsRequest,
  SearchLedgerContacts200Response,
  ContactImported,
  ContactCurrentPosition,
  ContactSearchFilter,
} from 'ldt-ledger-service-api-v2';
// import { ContactCurrentPosition, ContactSearchFilter } from 'ldt-ledger-service-api-v2/model';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Ledger } from 'ldt-ledger-service-api';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import {
  LedgerContactsService as LedgerContactsServiceV1,
  LedgersService as LedgerServiceV1,
} from 'ldt-ledger-service-api';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import {
  FindRequest,
  FindRequestMatchesInner,
  FindRequestMatchesInnerFieldsInner,
  SearchService,
} from 'ldt-people-api';

@Component({
  selector: 'app-ledger-contacts-v2',
  templateUrl: './ledger-contacts-v2.component.html',
  styleUrls: ['./ledger-contacts-v2.component.scss'],
  providers: [DatePipe],
})
export class LedgerContactsV2Component implements OnInit {
  ledger: Ledger;
  orgId: string;

  private gridApi: GridApi;
  gridColumnApi: ColumnApi;
  rowData: Contact[];

  canEdit: boolean = false;
  canAdmin: boolean = false;
  downloading = false;
  viewCount = 0;
  countUpdating = true;
  refreshing: boolean = true;
  rowsSelected = false;
  isAdmin: Observable<boolean>;
  orgHasReinit: boolean = false;

  // AG-GRID
  rowModelType: string = 'serverSide';
  serverSideDatasource: IServerSideDatasource = this.dwDatasource();
  cacheBlockSize = 1000;
  rowSelection = 'multiple';
  masterDetail = true;

  paginationToken: string | undefined;

  localStorageKeyName = 'ag-grid-columns-ledger-contacts-v2';

  boolSearchFields = ['is_verified', 'has_multiple_jobs'];

  constructor(
    private datePipe: DatePipe,
    private notify: NotificationService,
    private ledgerService: LedgerContactsService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ledgerContactsV1: LedgerContactsServiceV1,
    private ledgerServiceV1: LedgerServiceV1,
    private auth: AuthService,
    private peopleService: SearchService
  ) {}

  ngOnInit(): void {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }
    this.canEdit = this.auth.userHasRole('editor');
    this.canAdmin = this.auth.userHasRole('admin');

    this.isAdmin = this.auth.$isAdmin;

    let orgV = this.auth.getOrgsValue.find((o) => {
      return o.id === this.auth.getSelectedOrgIdValue;
    });
    this.orgHasReinit = orgV?.settings?.ledger?.allowReinit || false;
  }

  /// AG -GRID --------------
  betterDateFilterParams: ITextFilterParams = {
    filterOptions: [
      'equals',
      {
        displayKey: 'greaterThanOrEqualsCustom',
        displayName: 'Greater than or equals',
        predicate: ([filterValue], cellValue) => true,
      },
      {
        displayKey: 'lessThanOrEqualsCustom',
        displayName: 'Less than or equals',
        predicate: ([filterValue], cellValue) => true,
      },
      'notEqual',
      'inRange',
      'blank',
      'notBlank',
    ],
    defaultOption: 'greaterThanOrEqualsCustom',
    maxNumConditions: 1,
  };
  private textFilterParams: ITextFilterParams = {
    filterOptions: ['startsWith', 'equals', 'notEqual', 'blank', 'notBlank'],
    defaultOption: 'startsWith',
    maxNumConditions: 1,
  };
  private booleanFilterParams: any = {
    values: ['true', 'false'],
    suppressMiniFilter: true,
  };
  private numberFilterParams: ITextFilterParams = {
    filterOptions: [
      'equals',
      'notEqual',
      'lessThan',
      'lessThanOrEqual',
      'greaterThan',
      'greaterThanOrEqual',
      'inRange',
      'blank',
      'notBlank',
    ],
    defaultOption: 'greaterThanOrEqual',
    maxNumConditions: 1,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: this.textFilterParams,
    resizable: true,
    // flex: 1,
    minWidth: 100,
    width: 150,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  sideBar: SideBarDef = {
    defaultToolPanel: 'columns',
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
  };
  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      headerName: '',
      maxWidth: 40,
      checkboxSelection: true,
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return '';
      },
      headerTooltip: 'Unique ID of this contact in the Live Data system',
      suppressColumnsToolPanel: true,
    },
    {
      field: 'is_verified',
      headerName: 'Verified?',
      maxWidth: 80,
      cellRenderer: (params: { value: any }) => {
        if (params.value) {
          return '<img src="../assets/icons/verified_user_black_18dp.svg" alt="true" />';
        } else {
          return '';
        }
      },
      filterParams: this.booleanFilterParams,
      filter: 'agSetColumnFilter',
      headerTooltip: 'Indicates whether Live Data was able to locate this person on the open web',
    },
    {
      headerName: 'Last Changed (UTC)',
      children: [
        {
          field: 'last_company_changed_at',
          headerName: 'Company',
          filter: 'agDateColumnFilter',
          filterParams: this.betterDateFilterParams,
          sort: 'desc',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          headerTooltip:
            'The last time (in UTC) Live Data updated this persons company on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.',
        },
        {
          field: 'last_info_changed_at',
          headerName: 'Other Info',
          filter: 'agDateColumnFilter',
          filterParams: this.betterDateFilterParams,
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          headerTooltip:
            'The last time (in UTC) Live Data updated any of this persons information on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.',
        },
      ],
    },
    {
      headerName: 'Person Info',
      children: [
        {
          field: 'name',
          valueGetter: (params: any) => {
            return params.data.name || params.data.imported?.name || '';
          },
          headerTooltip: 'The name of this person according to Live Data',
        },
        {
          field: 'linkedin',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/in/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
          headerTooltip: 'The authoritative LinkedIn ID of this person according to Live Data.',
        },
        { field: 'country', headerTooltip: 'The country in which this person resides' },
        { field: 'location', headerTooltip: 'The general location in which this person resides' },
        {
          field: 'has_multiple_jobs',
          headerName: 'Multiple Jobs?',
          filterParams: this.booleanFilterParams,
          filter: 'agSetColumnFilter',
          headerTooltip: 'Whether this person has multiple current jobs',
        },
      ],
    },
    {
      headerName: 'Current Job',
      children: [
        {
          field: 'current_position.company.name',
          headerName: 'Company',
          headerTooltip: 'The verified company at which this person currently works',
        },
        {
          field: 'current_position.title',
          headerName: 'Title',
          headerTooltip: 'The verified title this person currently holds',
        },
        {
          field: 'current_position.level',
          headerName: 'Job Level',
          headerTooltip: 'The inferred level of the current job, based on the title.',
          filterParams: { values: Object.values(ContactCurrentPosition.LevelEnum) },
          filter: 'agSetColumnFilter',
        },
        {
          field: 'current_position.function',
          headerName: 'Job Function',
          headerTooltip: 'The inferred function of the current job, based on the title.',
          filterParams: { values: Object.values(ContactCurrentPosition.FunctionEnum) },
          filter: 'agSetColumnFilter',
        },
        {
          field: 'current_position.location',
          headerName: 'Location',
          headerTooltip: 'The general location of the current job',
        },
        {
          field: 'current_position.started_at',
          headerName: 'Start Date',
          headerTooltip: 'The date the person started at their current job',
          filter: 'agDateColumnFilter',
          filterParams: this.betterDateFilterParams,
        },
      ],
    },
    {
      headerName: 'Current Contact Info',
      children: [
        {
          field: 'current_position.email',
          headerName: 'Email',
          headerTooltip: 'The current email address of the contact, if known.',
        },
        {
          field: 'current_position.email_status',
          headerName: 'Email Status',
          filterParams: { values: Object.values(ContactCurrentPosition.EmailStatusEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip:
            'The status of the email address. "valid" denotes a valid email, "catch-all" denotes a mail server that does not validate emails, and "best-guess" denotes a likely email address that could not be positively confirmed.',
        },
      ],
    },
    {
      headerName: 'Current Company Info',
      children: [
        {
          field: 'current_position.company.linkedin',
          headerName: 'LinkedIn',
          headerTooltip: 'The LinkedIn URL for the current company.',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/company/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
        },
        {
          field: 'current_position.company.domain',
          headerName: 'Domain',
          headerTooltip: 'The domain for the current company.',
        },
        {
          field: 'current_position.company.employee_count',
          headerName: 'Employee Count',
          headerTooltip: 'The number of employees at the current company.',
          filter: 'agNumberColumnFilter',
          filterParams: this.numberFilterParams,
        },
        {
          field: 'current_position.company.industry',
          headerName: 'Industry',
          headerTooltip: 'The industry of the current company.',
        },
        {
          field: 'current_position.company.country',
          headerName: 'Country',
          headerTooltip: 'The country of the company headquarters.',
        },
        {
          field: 'current_position.company.location',
          headerName: 'Location',
          headerTooltip: 'The geographic location of the company headquarters.',
        },
      ],
    },
    {
      headerName: 'Imported Data',
      children: [
        {
          field: 'imported.reference_id',
          headerName: 'Ref ID',
          headerTooltip: 'Your unique/internal ID used to reference this person in your systems',
        },
        {
          field: 'imported.name',
          headerName: 'Name',
          headerTooltip: 'The name of the person that was provided during import',
        },
        {
          field: 'imported.linkedin',
          headerName: 'LinkedIn',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/in/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
          headerTooltip: 'The LinkedIn URL that was provided during import',
        },
        {
          field: 'imported.company',
          headerName: 'Company',
          headerTooltip: 'The name of the company that was provided during import',
        },
        {
          field: 'imported.title',
          headerName: 'Title',
          headerTooltip: 'The title of the peron that was provided during import',
        },
        {
          field: 'imported.derived_level',
          headerName: 'Derived Level',
          filterParams: { values: Object.values(ContactImported.DerivedLevelEnum) },
          headerTooltip: 'The inferred level of the title this contact was imported with.',
        },
        {
          field: 'imported.derived_function',
          headerName: 'Derived Function',
          filterParams: { values: Object.values(ContactImported.DerivedFunctionEnum) },
          headerTooltip: 'The inferred function of the title this contact was imported with.',
        },
      ],
    },
  ];

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  dwDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.hideOverlay();
        const size = (params.request.endRow || 0) - (params.request.startRow || 0);

        // If the grid is back at the top, reset the pagination token
        if (params.request.startRow === 0) {
          delete this.paginationToken;
          this.countUpdating = true;
        }

        const searchFilters = this.agGridFiltersToSearchFilters(params.request.filterModel);

        var sortBy = undefined;
        if (params.request.sortModel.length > 0) {
          sortBy = params.request.sortModel[0].colId;
          if (params.request.sortModel[0].sort == 'desc') {
            sortBy = '-' + sortBy;
          }
        }

        let searchRequest: SearchLedgerContactsRequest = {
          filters: searchFilters,
          size: size,
          pagination_token: this.paginationToken,
          sort_by: sortBy,
        };

        this.ledgerService
          .searchLedgerContacts(this.ledger.id, this.orgId, searchRequest)
          .subscribe({
            next: (res: SearchLedgerContacts200Response) => {
              params.success({
                rowData: res.contacts || [],
              });
              this.paginationToken = res.pagination_token || undefined;
              this.viewCount = res.count || 0;
              this.refreshing = false;
              this.countUpdating = false;
            },
            error: () => {
              params.fail();
              this.notify.error('Oops. There was an error getting the data.');
              this.refreshing = false;
              this.viewCount = 0;
              this.countUpdating = false;
            },
          });
      },
    };
  }

  agGridFiltersToSearchFilters(filterModel: any): ContactSearchFilter[] {
    let searchFilters: ContactSearchFilter[] = [];

    Object.keys(filterModel).forEach((fieldName: any) => {
      var match_type;
      let search_type = ContactSearchFilter.TypeEnum.Must;

      let filter: ContactSearchFilter = {
        field: fieldName,
        type: search_type,
      };

      switch (filterModel[fieldName].filterType) {
        case 'set':
          if (filterModel[fieldName].values.length == 0) {
            return;
          }

          filter.match_type = ContactSearchFilter.MatchTypeEnum.Exact;
          filter.type = ContactSearchFilter.TypeEnum.Must;
          if (this.boolSearchFields.includes(fieldName)) {
            if (filterModel[fieldName].values.length == 1) {
              filter.boolean_value = filterModel[fieldName].values[0];
            } else {
              return;
            }
          } else {
            filter.string_values = filterModel[fieldName].values;
          }
          break;

        case 'text':
          match_type = ContactSearchFilter.MatchTypeEnum.Exact;
          search_type = ContactSearchFilter.TypeEnum.Must;

          switch (filterModel[fieldName].type) {
            case 'startsWith':
              match_type = ContactSearchFilter.MatchTypeEnum.Fuzzy;
              search_type = ContactSearchFilter.TypeEnum.Must;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'equals':
              match_type = ContactSearchFilter.MatchTypeEnum.Exact;
              search_type = ContactSearchFilter.TypeEnum.Must;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'notEqual':
              match_type = ContactSearchFilter.MatchTypeEnum.Exact;
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'blank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.Must;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          break;
        case 'number':
          var search_min;
          var search_max;
          switch (filterModel[fieldName].type) {
            case 'equals':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filter;
              break;
            case 'notEqual':
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filter;
              break;
            case 'lessThan':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_max = filterModel[fieldName].filter - 1;
              break;
            case 'lessThanOrEqual':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_max = filterModel[fieldName].filter;
              break;
            case 'greaterThan':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter - 1;
              break;
            case 'greaterThanOrEqual':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              break;
            case 'inRange':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filterTo;
              break;
            case 'blank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.Must;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          if (search_min) filter.number_min = search_min;
          if (search_max) filter.number_max = search_max;
          if (match_type) filter.match_type = match_type;

          break;
        case 'date':
          var search_from;
          var search_to;
          const parsed_from = filterModel[fieldName].dateFrom
            ? moment(new Date(filterModel[fieldName].dateFrom)).format('YYYY-MM-DD')
            : undefined;
          const parsed_to = filterModel[fieldName].dateTo
            ? moment(new Date(filterModel[fieldName].dateTo)).format('YYYY-MM-DD')
            : undefined;

          switch (filterModel[fieldName].type) {
            case 'equals':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              search_to = parsed_from;
              break;
            case 'notEqual':
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              search_from = parsed_from;
              search_to = parsed_from;
              break;
            case 'lessThanOrEqualsCustom':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_to = parsed_from;
              break;
            case 'greaterThanOrEqualsCustom':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              break;
            case 'inRange':
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              search_to = parsed_to;
              break;
            case 'blank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = ContactSearchFilter.MatchTypeEnum.Exists;
              search_type = ContactSearchFilter.TypeEnum.Must;
              break;
            default:
              search_type = ContactSearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          if (search_from) filter.date_from = search_from;
          if (search_to) filter.date_to = search_to;
          if (match_type) filter.match_type = match_type;

          break;
      }

      searchFilters.push(filter);
    });

    return searchFilters;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const savedColumnState = localStorage.getItem(this.localStorageKeyName);

    // Get the saved column state from localstorage if a user has hidden certain columns
    // But also reset the sort order to last job change desc
    if (savedColumnState) {
      this.gridApi.closeToolPanel();

      if (
        this.gridColumnApi.getColumnState().length != JSON.parse(savedColumnState || '{}').length
      ) {
        this.notify.info(
          'Grid columns have changed, so your previous view preferences have been reset'
        );
        this.saveColumnState();
        this.gridApi.openToolPanel('columns');
      } else {
        this.gridColumnApi.applyColumnState({ state: JSON.parse(savedColumnState || '{}') });
      }
    }
    this.gridColumnApi.applyColumnState({
      state: [{ colId: 'last_company_changed_at', sort: 'desc' }],
      defaultState: { sort: null },
    });

    // Get any filtering specified in the querystring and apply it
    if (this.route.snapshot.queryParams.filter) {
      this.gridApi.setFilterModel(
        JSON.parse(decompressFromEncodedURIComponent(this.route.snapshot.queryParams.filter))
      );
    }
  }

  saveColumnState() {
    if (this.gridColumnApi) {
      const currentState = this.gridColumnApi.getColumnState();
      localStorage.setItem(this.localStorageKeyName, JSON.stringify(currentState));
    }
  }

  deleteSelectedContacts() {
    var numToDelete = this.gridApi.getSelectedRows().length;
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.id);
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Contact Deletion',
        message:
          'Are you sure you want to delete ' +
          numToDelete +
          ' contacts? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.ledgerContactsV1
          .bulkDeleteLedgerContacts(this.orgId, this.ledger.id, { contactIds: ids })
          .subscribe({
            next: (response) => {
              this.gridApi.deselectAll();
              this.gridApi.purgeInfiniteCache();
              this.notify.success(response.countRowsDeleted + ' contacts successfully deleted.');
            },
            error: () => {
              this.notify.error(
                'Oops. There was an error during your request. Please try again later.'
              );
            },
          });
      }
    });
  }

  // Refresh the data grid. If the request was manual (user hit the button) we always do it. If it was requested
  //  automatically (usually from polling), then we only refresh if the user has no active filters (https://app.clickup.com/t/1wzer4f)
  refreshData(manual: boolean = true) {
    if (this.gridApi) {
      if (manual || Object.keys(this.gridApi.getFilterModel()).length == 0) {
        this.refreshing = true;
        this.gridApi.refreshServerSide({ purge: true });
      }
    }
  }

  inspect() {
    var ids: string[] = this.gridApi.getSelectedRows().map((d: any) => d.id);
    const url = '/datawarehouse/inspect?ref=' + ids.join(',');
    window.open(url, '_blank');
  }

  ContactFieldsForFind: string[] = ['name', 'company', 'title', 'linkedin'];
  FindScoreThresholds: { [key: string]: number } = {
    low: 30,
    medium: 75,
    high: 92,
  };
  find() {
    var matches: FindRequestMatchesInner[] = this.gridApi
      .getSelectedRows()
      .slice(0, 1)
      .map((contact: Contact) => {
        let fields: FindRequestMatchesInnerFieldsInner[] = [];

        this.ContactFieldsForFind.forEach((field) => {
          let findFieldName = field;
          if (field === 'company') {
            findFieldName = 'company.name';
          }
          if (contact.imported[field as keyof ContactImported]) {
            fields.push({
              field_name: findFieldName,
              search_term: contact.imported[field as keyof ContactImported] as string, // The above fields are always strings
            });
          }
        });

        return { fields: fields };
      });

    const req: FindRequest = {
      matches: matches,
      confidence: 'low',
    };

    const orgId = this.auth.getOrgsValue[0].id;
    this.peopleService.find(orgId, req).subscribe({
      next: (response) => {
        const match = response.matches![0];

        if (match.people!.length === 0) {
          this.notify.info('No matches found');
          return;
        }

        let confidence = 'none';
        if (match.people![0].score) {
          for (const key in this.FindScoreThresholds) {
            if (this.FindScoreThresholds[key] <= match.people![0].score) {
              confidence = key;
            }
          }
        }

        let foundMessage = 'Found ' + match.people!.length + ' people. ';
        if (match.people![0].score) {
          foundMessage += 'Top score ' + match.people![0].score + '(' + confidence + ') -- ';
        } else {
          foundMessage += 'Exact match found -- ';
        }
        foundMessage += match.people![0].linkedin;

        this.notify.info(foundMessage);
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      },
    });
  }

  downloadView() {
    this.downloading = true;
    const searchFilters = this.agGridFiltersToSearchFilters(this.gridApi.getFilterModel());

    let searchRequest: SearchLedgerContactsRequest = {
      filters: searchFilters,
    };

    this.ledgerService.downloadLedgerContacts(this.ledger.id, this.orgId, searchRequest).subscribe({
      next: (response: any) => {
        var data = new Blob([response], { type: 'text/csv' });
        FileSaver.saveAs(
          data,
          'livedata-' + this.ledger.name + '-' + moment().format('YYYYMMDD-HHmmss') + '.csv'
        );
        this.downloading = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.downloading = false;
      },
    });
  }

  downloadAll() {
    this.downloading = true;

    this.ledgerService.downloadLedgerContacts(this.ledger.id, this.orgId, {}).subscribe({
      next: (response: any) => {
        var data = new Blob([response], { type: 'text/csv' });
        FileSaver.saveAs(
          data,
          'livedata-' + this.ledger.name + '-' + moment().format('YYYYMMDD-HHmmss') + '.csv'
        );
        this.downloading = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.downloading = false;
      },
    });
  }

  reiniting = false;
  reinitLedger() {
    this.reiniting = true;
    this.ledgerServiceV1.reinitLedgerContacts(this.orgId, this.ledger.id).subscribe(
      () => {
        this.notify.success('All unknown contacts have been sent to the queue');
        this.reiniting = false;
      },
      (err) => {
        if (err.status && err.status === 409) {
          this.notify.error('Unable to reinit. Only allowed every 7 days per ledger');
        } else {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
        }
        this.reiniting = false;
      }
    );
  }
}
