import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';

import { MxService } from './mx-service/mx.service';
import { NotificationService } from './notification-service/notification.service';
import { FilterPipe } from './pipes/filter.pipe';
import { CustomStepperComponent } from './stepper/stepper.component';
import { TestStatusBadgeComponent } from './test-status-badge/test-status-badge.component';
import { JobFunctionDropdownComponent } from './job-function-dropdown/job-function-dropdown.component';
import { BadgeComponent } from './badge/badge.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateRangePickerCustomRangePanelComponent } from './date-range-picker/custom-range-panel/custom-range-panel.component';

@NgModule({
  declarations: [
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    BadgeComponent,
    DateRangePickerComponent,
    DateRangePickerCustomRangePanelComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCardModule,
  ],
  exports: [
    ReactiveFormsModule,
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    BadgeComponent,
    MatIconModule,
    DateRangePickerComponent,
  ],
  providers: [NotificationService, MxService],
})
export class SharedModule {}
