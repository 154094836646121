import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

import * as moment from 'moment';

const customPresets = [
  'last 6 months',
  'year to date',
  'last 12 months',
  'last 2 years',
  'last 5 years',
  'last 10 years',
] as const;
type CustomPreset = (typeof customPresets)[number];

@Component({
  selector: 'app-custom-range-panel',
  templateUrl: './custom-range-panel.component.html',
  styleUrls: ['./custom-range-panel.component.scss'],
})
export class DateRangePickerCustomRangePanelComponent<D> {
  // list of range presets we want to provide:
  readonly customPresets = customPresets;

  constructor(
    private dateAdapter: DateAdapter<D>,
    public picker: MatDateRangePicker<D>
  ) {}

  selectRange(rangeName: CustomPreset): void {
    const [start, end] = this.calculateDateRange(rangeName);

    this.picker.select(start);
    this.picker.select(end);
    this.picker.close();
  }

  private calculateDateRange(rangeName: CustomPreset): [start: D, end: D] {
    const thisMonth = moment().day(1);
    const end = this.getDateFromMoment(thisMonth);

    switch (rangeName) {
      case 'last 6 months':
        return [this.getDateFromMoment(thisMonth.subtract(6, 'months')), end];
      case 'year to date': {
        return [this.getDateFromMoment(thisMonth.month(0)), end];
      }
      case 'last 12 months': {
        return [this.getDateFromMoment(thisMonth.subtract(12, 'months')), end];
      }
      case 'last 2 years': {
        return [this.getDateFromMoment(thisMonth.subtract(2, 'years')), end];
      }
      case 'last 5 years': {
        return [this.getDateFromMoment(thisMonth.subtract(5, 'years')), end];
      }
      case 'last 10 years': {
        return [this.getDateFromMoment(thisMonth.subtract(10, 'years')), end];
      }
      default:
        // exhaustiveness check;
        // rangeName has type never, if every possible value is handled in the switch cases.
        // Otherwise, the following line will result in compiler error:
        // "Type 'string' is not assignable to type '[start: D, end: D]'"
        return rangeName;
    }
  }

  private getDateFromMoment(date: moment.Moment): D {
    return this.dateAdapter.createDate(date.year(), date.month(), date.date());
  }
}
