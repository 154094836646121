import {
  Component,
  EventEmitter,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Subscription } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

// We're trying to move to dayjs, but Material doesn't support it yet
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRangePickerCustomRangePanelComponent } from './custom-range-panel/custom-range-panel.component';
import { debounceTime } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangePickerComponent implements AfterViewInit, OnDestroy {
  @Input() startRange: [Date, Date] | undefined;
  @Output() selectedDateRange = new EventEmitter<[moment.Moment, moment.Moment] | undefined>();
  @ViewChild('picker') picker: MatDatepicker<Date>;

  private pickerOpenedSub: Subscription; // subscription to the date picker opened: we need it b/c there is no way in Material v15 to add a custom class to the overlay (date picker dropdown) w/o this hack (after we switch to v16, we can remove this)

  readonly HeaderComponent = DateRangePickerCustomRangePanelComponent;

  // set earlisetdate 23 years ago so all the years fit on one page of the cal picker
  earliestDate = moment()
    .year(moment().year() - 23)
    .month(0)
    .date(1);
  latestDate = moment();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  ngOnInit() {
    if (this.startRange) {
      this.range.setValue({
        start: moment(this.startRange[0]),
        end: moment(this.startRange[1]),
      });
    }
  }

  ngAfterViewInit() {
    this.range.valueChanges.pipe(debounceTime(200)).subscribe((event) => {
      if (event.start && event.end) {
        this.selectedDateRange.emit([event.start, event.end]);
      }
    });

    // Add custom class to date picker overlay (dropdown)
    this.pickerOpenedSub = this.picker.openedStream.subscribe(() => {
      const overlayRef = this.picker['_overlayRef'] as OverlayRef; // access the overlay reference
      if (overlayRef && overlayRef.overlayElement) {
        overlayRef.overlayElement.classList.add('custom-datepicker-overlay');
      }
    });
  }

  // Unsubscribe from subscription applying a custom class to date picker overlay (dropdown)
  ngOnDestroy() {
    if (this.pickerOpenedSub) {
      this.pickerOpenedSub.unsubscribe();
    }
  }

  // Set the selected month and year
  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<any>) {
    const selectedDate = moment(normalizedMonthAndYear);

    // Handle the case where the user is selecting the start date
    if (!this.range.value.start || (this.range.value.start && this.range.value.end)) {
      datepicker.select(selectedDate.startOf('month')); // Select the start date

      // Close and re-open the picker to have it reset back to year view
      setTimeout(() => {
        datepicker.close();
        setTimeout(() => {
          datepicker.open(); // Reopen for selecting the end date
        }, 200);
      }, 0);
    } else if (this.range.value.start && !this.range.value.end) {
      datepicker.select(selectedDate.endOf('month'));
      // this.selectedDateRange.emit([this.range.value.start, this.range.value.end]);
      datepicker.close(); // Close picker once end date is selected
    }
  }

  endDateFilter = (date: Date | null): boolean => {
    // If both dates are selected, then go back to the default dates
    if (this.range.value.start && this.range.value.end) {
      return true;
    }
    const startDate = this.range.get('start')?.value;
    return date ? date >= startDate : false;
  };

  getHeaderComponent() {
    if (this.range.value.start && !this.range.value.end) {
      return null;
    }
    return this.HeaderComponent;
  }
}
