import { Component, OnDestroy } from '@angular/core';
import {
  Company,
  CreateEmployeeTenureReportRequest,
  FindCompany200Response,
} from 'ldt-moneyball-api';
import * as Highcharts from 'highcharts';
import { MoneyballService, SankeyNode } from '../moneyball/moneyball.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { NotificationService } from '../shared/notification-service/notification.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  arrDepChartNoSeries,
  sankeyChartNoSeries,
  tenureByMonthChartNoSeries,
  wheelChartNoSeries,
} from './chart-options';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as moment from 'moment';

export interface BattleUrlSettings {
  companies: string[];
  jobFunction?: string | undefined;
  tenureStatus?: 'current' | 'departed';
  headcountValueType?: 'percentage' | 'number';
}

interface ChartedCompany {
  company: Company;
  colorIndex: number;
  sankeyData?: SankeyNode[];
}

@Component({
  selector: 'app-moneyball-battle',
  templateUrl: './moneyball-battle.component.html',
  styleUrls: ['./moneyball-battle.component.scss'],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MoneyballBattleComponent implements OnDestroy {
  private dataSubscriptions = new Subscription();

  companies: { [key: string]: ChartedCompany } = {};
  charts: { [key: string]: Highcharts.Chart } = {};

  selectedHeadcountChartValueType: 'percentage' | 'number' = 'percentage';
  selectedTenureChartStatus: 'current' | 'departed' = 'current';
  availableJobFunctions: string[] = this.moneyballService.JobFunctions;
  selectedJobFunction: string | undefined = undefined;

  showCharts: boolean = false;
  maxCompanies: number = 10;

  Highcharts: typeof Highcharts = Highcharts;

  dateRange: [Date, Date] = [
    new Date(Date.UTC(2022, 0, 1, 0, 0, 0)),
    new Date(Date.UTC(2024, new Date().getMonth() + 1, 1, 0, 0, 0)),
  ];

  constructor(
    private moneyballService: MoneyballService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService
  ) {}

  companySelected(event: { company: Company; identifier: string }) {
    if (!event) return;
    if (!event.company) return;

    // if company ID is duplicated, bail and show error
    if (event.company.id in this.companies) {
      this.notify.error('Company already selected. Please select a different company.');
      return;
    }

    // We assign a color when we create the entry, and persist it so that adding/removing series
    // maintains the same color for each company (at least for this session)
    this.companies[event.company.id] = {
      company: event.company,
      colorIndex: this.getNextColor(),
    };

    this.updateUrl();
    this.updateCharts(this.companies[event.company.id]);
  }

  removeCompanyFromList(id: string) {
    delete this.companies[id];
    this.removeSeries(id);
    this.updateUrl();
  }

  companiesAsArray(): Company[] {
    return Object.keys(this.companies).map((c) => this.companies[c].company);
  }

  removeSeries(companyID: string) {
    // for each chart, remove the series with the company id
    Object.keys(this.charts).forEach((key) => {
      this.charts[key].series.forEach((series) => {
        if (series.options.custom?.id === companyID) {
          series.remove();
        }
      });
    });

    // The sankey draws from the companies dict, so just redrawing is fine since we deleted this company
    this.redrawSankeyChart();
  }

  updateCharts(company: ChartedCompany) {
    this.updateEmployeeCountChart(company);
    this.updateTenureChart(company);
    this.updateSankeyChart(company);
  }

  updateEmployeeCountChart(company: ChartedCompany) {
    if (!company) return;

    const relativeData = this.selectedHeadcountChartValueType === 'percentage';

    const sub = this.moneyballService
      .getHistoricalEmployeeCounts(
        [company.company.id!],
        this.dateRange[0],
        this.dateRange[1],
        this.selectedJobFunction,
        relativeData
      )
      .subscribe({
        next: (res) => {
          let chartTitle = 'Headcount';
          if (this.selectedJobFunction) {
            chartTitle = this.selectedJobFunction + ' Headcount';
          }
          this.charts['arrDep'].setTitle({ text: chartTitle });

          const data =
            this.selectedHeadcountChartValueType === 'percentage'
              ? res.map((point) => [point[0], point[1] * 100]) // convert to percentage
              : res; // keep as number otherwise

          this.charts['arrDep'].addSeries({
            name: company.company.name,
            type: 'spline',
            data: data,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.dataSubscriptions.add(sub);
  }

  updateTenureChart(company: ChartedCompany) {
    if (!company) return;

    const sub = this.moneyballService
      .getHistoricalTenureData(
        company.company.id!,
        this.dateRange[0],
        this.dateRange[1],
        this.selectedTenureChartStatus as CreateEmployeeTenureReportRequest.StatusEnum,
        this.selectedJobFunction
      )
      .subscribe({
        next: (res) => {
          let chartTitle = 'Tenure by Month';
          if (this.selectedJobFunction) {
            chartTitle = this.selectedJobFunction + ' Tenure by Month';
          }
          this.charts['tenure'].setTitle({ text: chartTitle });

          this.charts['tenure'].addSeries({
            name: company.company.name,
            type: 'line',
            data: res,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.dataSubscriptions.add(sub);
  }

  updateSankeyChart(company: ChartedCompany) {
    if (!company) return;

    const sub = this.moneyballService
      .getSankeyData(
        company.company,
        this.dateRange[0],
        this.dateRange[1],
        10,
        this.selectedJobFunction
      )
      .subscribe({
        next: (res: SankeyNode[]) => {
          this.companies[company.company.id!].sankeyData = res;
          this.redrawSankeyChart();
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.dataSubscriptions.add(sub);
  }

  onDateRangeChanged(event: [moment.Moment, moment.Moment]) {
    this.dataSubscriptions.unsubscribe();
    this.dataSubscriptions = new Subscription();

    this.dateRange = [
      new Date(Date.UTC(event[0].year(), event[0].month(), 1, 0, 0, 0)),
      new Date(Date.UTC(event[1].year(), event[1].month(), 1, 0, 0, 0)),
    ];

    this.removeAllSeriesFromChart('arrDep');
    this.removeAllSeriesFromChart('tenure');
    Object.keys(this.companies).forEach((c) => {
      this.companies[c].sankeyData = undefined;
    });
    this.redrawSankeyChart();

    Object.keys(this.companies).forEach((c) => {
      this.updateEmployeeCountChart(this.companies[c]);
      this.updateTenureChart(this.companies[c]);
      this.updateSankeyChart(this.companies[c]);
    });
  }

  // Get the next color in the highcharts theme that isn't already used
  getNextColor(): number {
    const colors = Highcharts.getOptions()?.colors;
    const usedColors = Object.keys(this.companies).map((c) => this.companies[c]?.colorIndex);
    if (colors) {
      for (let i = 0; i < colors.length; i++) {
        if (!usedColors.includes(i)) {
          return i;
        }
      }
    }
    return 0;
  }

  redrawSankeyChart() {
    const allData = Object.keys(this.companies)
      .filter((c) => c && this.companies[c] && this.companies[c].sankeyData)
      .map((c) => this.companies[c].sankeyData as SankeyNode[])
      .flat();
    let filteredData: SankeyNode[] = allData;
    let wheelData = [];

    // Remove the companies we're charting from the sources and destinations. LEaving them makes the chart
    // look weird, plus we have the lower chart to show the inter-company moves
    const companyNames = this.companiesAsArray().map((c) => c.name);
    filteredData = allData.filter((item) => {
      if (!item) return false;

      const interCompanyMove =
        companyNames.includes(item[0].trim()) && companyNames.includes(item[1].trim());

      return !interCompanyMove;
    });

    // For the wheel data, we're only showing the selected companies (that we didn't show in the above sankey)
    wheelData = allData.filter((item) => {
      const interCompanyMove =
        companyNames.includes(item[0].trim()) && companyNames.includes(item[1].trim());

      // The data appends a ` ` for the sankey chart - ignore those data points here
      return interCompanyMove && !item[0].endsWith(' ') && !item[1].endsWith(' ');
    });

    this.charts['poach'].series[0].update({
      type: 'dependencywheel',
      keys: ['from', 'to', 'weight'],
      data: wheelData,
    });

    this.charts['fromTo'].series[0].update({
      type: 'sankey',
      keys: ['from', 'to', 'weight'],
      data: filteredData,
    });
  }

  toggleHeadcountValueType(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedHeadcountChartValueType = value.value;
    this.updateHeadcountChartAxes(this.selectedHeadcountChartValueType);
    this.updateUrl();

    this.removeAllSeriesFromChart('arrDep');

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateEmployeeCountChart(this.companies[c]);
      }
    });
  }

  updateHeadcountChartAxes(headcountType: string) {
    this.charts['arrDep'].update({
      yAxis: {
        labels: {
          formatter: function () {
            const pointValue = this.value as number;
            return headcountType === 'percentage' ? pointValue.toFixed(0) + '%' : pointValue;
          },
        },
        title: {
          text: headcountType === 'percentage' ? 'Pct Change' : 'Number of Employees',
        },
      },
      tooltip: {
        pointFormat:
          this.selectedHeadcountChartValueType === 'percentage'
            ? '{series.name}: <b>{point.y:.2f}%</b>'
            : '{series.name}: <b>{point.y}</b>',
      },
    } as Highcharts.Options);
  }

  toggleTenureStatus(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedTenureChartStatus = value.value;
    this.updateUrl();

    this.removeAllSeriesFromChart('tenure');

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateTenureChart(this.companies[c]);
      }
    });
  }

  removeAllSeriesFromChart(chartName: string) {
    while (this.charts[chartName].series.length > 0) {
      this.charts[chartName].series[0].remove(false);
    }
  }

  updateUrl(): void {
    const settings: BattleUrlSettings = {
      companies: Object.keys(this.companies).map((c) => this.companies[c].company.id),
      jobFunction: this.selectedJobFunction,
      tenureStatus: this.selectedTenureChartStatus,
      headcountValueType: this.selectedHeadcountChartValueType,
    };

    const encodedFilter = encodeURIComponent(JSON.stringify(settings));
    if (
      !this.route.snapshot.queryParams.settings ||
      this.route.snapshot.queryParams.settings !== encodedFilter
    ) {
      const url = '/moneyball/battle?settings=' + encodedFilter;
      this.router.navigateByUrl(url);
    }
  }

  decodeUrl(encodedString: string) {
    try {
      const decodedString = decodeURIComponent(encodedString);
      return JSON.parse(decodedString);
    } catch (error) {
      this.notify.error('Error getting data.');
      console.error('Error decoding the string:', error);
      return null;
    }
  }

  goToMoneyball(companyId: string) {
    this.router.navigate(['/moneyball'], {
      queryParams: {
        id: companyId,
      },
    });
  }

  ngAfterViewInit(): void {
    this.charts['arrDep'] = Highcharts.chart('arrDepChartContainer', arrDepChartNoSeries);
    this.charts['tenure'] = Highcharts.chart('tenureChartContainer', tenureByMonthChartNoSeries);
    this.charts['fromTo'] = Highcharts.chart('fromToChartContainer', sankeyChartNoSeries);
    this.charts['poach'] = Highcharts.chart('poachingChartContainer', wheelChartNoSeries);

    // Get any filtering specified in the querystring and apply it
    try {
      if (this.route.snapshot.queryParams.settings) {
        const settings: BattleUrlSettings = this.decodeUrl(
          this.route.snapshot.queryParams.settings
        );
        if (settings) {
          if (settings.headcountValueType) {
            this.selectedHeadcountChartValueType = settings.headcountValueType;
          }
          if (settings.tenureStatus) {
            this.selectedTenureChartStatus = settings.tenureStatus;
          }

          this.updateHeadcountChartAxes(this.selectedHeadcountChartValueType);

          forkJoin(
            settings.companies
              .filter((c) => c !== undefined)
              .map((c) => this.moneyballService.getCompany(c))
          ).subscribe({
            next: (res: (FindCompany200Response | undefined)[]) => {
              this.selectedJobFunction = settings.jobFunction;

              res.forEach((c) => {
                if (!c) return;
                if (!c.companies) return;
                if ((c.count || 0) < 1) return;

                const chartedCompany: ChartedCompany = {
                  company: c.companies[0],
                  colorIndex: this.getNextColor(),
                };

                this.companies[c.companies[0].id] = chartedCompany;
                this.updateCharts(chartedCompany);
              });
            },
          });
        }
      }
    } catch (error) {
      this.notify.error('Error getting data.');
      console.error('Error getting data from the querystring:', error);
      this.updateUrl();
    }
  }

  // Job Function dropdown component code
  onJobFunctionSelected(value: string | undefined) {
    if (!value) return;

    this.dataSubscriptions.unsubscribe();
    this.dataSubscriptions = new Subscription();

    this.selectedJobFunction = value === 'all' ? undefined : value;
    this.updateUrl();

    this.removeAllSeriesFromChart('arrDep');
    this.removeAllSeriesFromChart('tenure');

    Object.keys(this.companies).forEach((c) => {
      this.updateEmployeeCountChart(this.companies[c]);
      this.updateTenureChart(this.companies[c]);
    });
  }

  ngOnDestroy() {
    this.dataSubscriptions.unsubscribe();
  }
}
