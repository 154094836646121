<label for="start-date" class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
  Enter a date range
</label>
<mat-form-field appearance="outline" class="moneyball-battle-datepicker">
  <mat-date-range-input
    [formGroup]="range"
    [rangePicker]="picker"
    [dateFilter]="endDateFilter"
    [min]="earliestDate"
    [max]="latestDate">
    <input
      id="start-date"
      matStartDate
      formControlName="start"
      (click)="picker.open()"
      placeholder="Start date" />
    <input matEndDate formControlName="end" (click)="picker.open()" placeholder="End date" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker
    #picker
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, picker)"
    [calendarHeaderComponent]="getHeaderComponent()"></mat-date-range-picker>
</mat-form-field>
