import { Injectable, IterableDiffers } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  CompaniesService,
  Company,
  CreateEmployeeDemoReport200ResponseInner,
  CreateEmployeeDemoReportRequest,
  CreateEmployeeTenureReport200ResponseInner,
  CreateEmployeeTenureReportRequest,
  FindCompanyRequest,
} from 'ldt-moneyball-api';
import { Observable, forkJoin, map, of } from 'rxjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export type SankeyNode = [string, string, number];

@Injectable({
  providedIn: 'root',
})
export class MoneyballService {
  constructor(private companiesService: CompaniesService) {}

  getHistoricalEmployeeCounts(
    companies: string[],
    startDate: Date,
    endDate: Date,
    dept: string | undefined = undefined,
    diffs: boolean = false
  ): Observable<number[][]> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: companies,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      include_grouped_companies: true,
      group_by: dept ? [CreateEmployeeDemoReportRequest.GroupByEnum.Department] : undefined,
    };
    return this.companiesService.createEmployeeDemoReport(req).pipe(
      map((res) => {
        let response = res;
        if (dept) {
          response = res.filter(
            (item: CreateEmployeeDemoReport200ResponseInner) => item.group_values[0].value === dept
          );
        }

        if (!diffs) {
          return response
            .map((item: CreateEmployeeDemoReport200ResponseInner) => {
              return [new Date(item.date!).valueOf(), item.count_employees];
            })
            .sort((a, b) => a[0] - b[0]);
        }

        const sortedData = response.sort(
          (a, b) => new Date(a.date!).valueOf() - new Date(b.date!).valueOf()
        );
        const startValue = sortedData[0].count_employees || 1;
        return sortedData.map((item, index) => {
          return [new Date(item.date!).valueOf(), (item.count_employees - startValue) / startValue];
        });
      })
    );
  }

  getHistoricalTenureData(
    companyId: string,
    startDate: Date,
    endDate: Date,
    status: CreateEmployeeTenureReportRequest.StatusEnum = CreateEmployeeTenureReportRequest
      .StatusEnum.Departed,
    dept: string | undefined = undefined
  ): Observable<{ x: number; y: number; sampleSize: number }[]> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [companyId],
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      status: status,
      group_by: dept ? [CreateEmployeeTenureReportRequest.GroupByEnum.Department] : undefined,
      include_grouped_companies: true,
    };

    return this.companiesService.createEmployeeTenureReport(req).pipe(
      map((res) => {
        let response = res;
        if (dept) {
          response = res.filter(
            (item: CreateEmployeeDemoReport200ResponseInner) => item.group_values[0].value === dept
          );
        }

        return response
          .map((item: CreateEmployeeTenureReport200ResponseInner) => {
            return {
              x: new Date(item.date!).valueOf(),
              y: item.avg_tenure,
              sampleSize: item.count_employees,
            };
          })
          .sort((a, b) => Number(a.x) - Number(b.x));
      })
    );
  }

  getSankeyData(
    company: Company,
    startDate: Date,
    endDate: Date,
    maxItems: number = 10,
    dept: string | undefined = undefined
  ): Observable<SankeyNode[]> {
    return forkJoin([
      this.getEmployeeNextCompanyData(company.id, startDate, endDate, dept),
      this.getEmployeePreviousCompanyData(company.id, startDate, endDate, dept),
    ]).pipe(
      map((res) => {
        return this.generateSankeySeries(company.name, res[0], res[1], maxItems);
      })
    );
  }

  generateSankeySeries(
    companyName: string,
    nextData: CreateEmployeeDemoReport200ResponseInner[],
    prevData: CreateEmployeeDemoReport200ResponseInner[],
    maxItems: number
  ): SankeyNode[] {
    let data: SankeyNode[] = [];

    data = data.concat(
      prevData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== 'None' && c.group_values[0].value !== companyName
        )
        .slice(0, maxItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner) => [
          c.group_values[0].value + ' ',
          companyName,
          c.count_employees,
        ])
    );

    data = data.concat(
      nextData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== 'None' && c.group_values[0].value !== companyName
        )
        .slice(0, maxItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner) => [
          companyName,
          c.group_values[0].value,
          c.count_employees,
        ])
    );

    return data;
  }

  getEmployeeNextCompanyData(
    companyId: string,
    startDate: Date,
    endDate: Date,
    dept: string | undefined = undefined
  ): Observable<any> {
    let groupings = [CreateEmployeeDemoReportRequest.GroupByEnum.NextCompany];
    dept = undefined;
    if (dept) {
      groupings.push(CreateEmployeeDemoReportRequest.GroupByEnum.Department);
    }

    let req: CreateEmployeeDemoReportRequest = {
      companies: [companyId],
      group_by: groupings,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: true,
    };
    return this.companiesService.createEmployeeDemoReport(req).pipe(
      map((res) => {
        if (dept) {
          return res.filter(
            (item: CreateEmployeeDemoReport200ResponseInner) => item.group_values[1].value === dept
          );
        }
        return res;
      })
    );
  }

  getEmployeePreviousCompanyData(
    companyId: string,
    startDate: Date,
    endDate: Date,
    dept: string | undefined = undefined
  ): Observable<any> {
    let groupings = [CreateEmployeeDemoReportRequest.GroupByEnum.PreviousCompany];

    dept = undefined;
    if (dept) {
      groupings.push(CreateEmployeeDemoReportRequest.GroupByEnum.Department);
    }

    let req: CreateEmployeeDemoReportRequest = {
      companies: [companyId],
      group_by: groupings,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: true,
    };
    return this.companiesService.createEmployeeDemoReport(req).pipe(
      map((res) => {
        if (dept) {
          return res.filter(
            (item: CreateEmployeeDemoReport200ResponseInner) => item.group_values[1].value === dept
          );
        }
        return res;
      })
    );
  }

  getCompany(id: string) {
    if (!id) return of(undefined);
    const req: FindCompanyRequest = {
      search_field: FindCompanyRequest.SearchFieldEnum.Id,
      search_value: id,
    };
    // Use an intermediate observable to catch the error and return undefined
    return this.companiesService.findCompany(req);
  }

  LocationToState = [
    { key: 'New York, New York, United States', state: 'NY' },
    { key: 'Greater Chicago Area', state: 'IL' },
    { key: 'New York City Metropolitan Area', state: 'NY' },
    { key: 'Houston, Texas, United States', state: 'TX' },
    { key: 'Los Angeles, California, United States', state: 'CA' },
    { key: 'San Francisco Bay Area', state: 'CA' },
    { key: 'Los Angeles Metropolitan Area', state: 'CA' },
    { key: 'Atlanta, Georgia, United States', state: 'GA' },
    { key: 'Austin, Texas, United States', state: 'TX' },
    { key: 'Seattle, Washington, United States', state: 'WA' },
    { key: 'Greater Boston', state: 'MA' },
    { key: 'San Diego, California, United States', state: 'CA' },
    { key: 'Washington, District of Columbia, United States', state: 'DC' },
    { key: 'San Francisco, California, United States', state: 'CA' },
    { key: 'Dallas-Fort Worth Metroplex', state: 'TX' },
    { key: 'Washington DC-Baltimore Area', state: 'DC' },
    { key: 'Denver, Colorado, United States', state: 'CO' },
    { key: 'Minneapolis, Minnesota, United States', state: 'MN' },
    { key: 'Miami, Florida, United States', state: 'FL' },
    { key: 'Brooklyn, New York, United States', state: 'NY' },
    { key: 'Dallas, Texas, United States', state: 'TX' },
    { key: 'Philadelphia, Pennsylvania, United States', state: 'PA' },
    { key: 'Atlanta Metropolitan Area', state: 'GA' },
    { key: 'Charlotte, North Carolina, United States', state: 'NC' },
    { key: 'Greater Philadelphia', state: 'PA' },
    { key: 'Boston, Massachusetts, United States', state: 'MA' },
    { key: 'Portland, Oregon, United States', state: 'OR' },
    { key: 'Greater Houston', state: 'TX' },
    { key: 'San Antonio, Texas, United States', state: 'TX' },
    { key: 'Phoenix, Arizona, United States', state: 'AZ' },
    { key: 'Greater Seattle Area', state: 'WA' },
    { key: 'San Jose, California, United States', state: 'CA' },
    { key: 'St Louis, Missouri, United States', state: 'MO' },
    { key: 'Orlando, Florida, United States', state: 'FL' },
    { key: 'Greater Minneapolis-St. Paul Area', state: 'MN' },
    { key: 'Las Vegas, Nevada, United States', state: 'NV' },
    { key: 'Indianapolis, Indiana, United States', state: 'IN' },
    { key: 'Cincinnati, Ohio, United States', state: 'OH' },
    { key: 'Columbus, Ohio, United States', state: 'OH' },
    { key: 'Tampa, Florida, United States', state: 'FL' },
    { key: 'Denver Metropolitan Area', state: 'CO' },
    { key: 'Detroit Metropolitan Area', state: 'MI' },
    { key: 'Greater Phoenix Area', state: 'AZ' },
    { key: 'Pittsburgh, Pennsylvania, United States', state: 'PA' },
    { key: 'St Paul, Minnesota, United States', state: 'MN' },
    { key: 'Nashville, Tennessee, United States', state: 'TN' },
    { key: 'Raleigh, North Carolina, United States', state: 'NC' },
    { key: 'Fort Lauderdale, Florida, United States', state: 'FL' },
    { key: 'Jacksonville, Florida, United States', state: 'FL' },
    { key: 'Milwaukee, Wisconsin, United States', state: 'WI' },
    { key: 'Miami-Fort Lauderdale Area', state: 'FL' },
    { key: 'Chicago, Illinois, United States', state: 'IL' },
    { key: 'Salt Lake City, Utah, United States', state: 'UT' },
    { key: 'Los Angeles County, California, United States', state: 'CA' },
    { key: 'Cleveland, Ohio, United States', state: 'OH' },
    { key: 'Fort Worth, Texas, United States', state: 'TX' },
    { key: 'Louisville, Kentucky, United States', state: 'KY' },
    { key: 'Kansas City, Missouri, United States', state: 'MO' },
    { key: 'Baltimore, Maryland, United States', state: 'MD' },
    { key: 'Sacramento, California, United States', state: 'CA' },
    { key: 'Richmond, Virginia, United States', state: 'VA' },
    { key: 'Omaha, Nebraska, United States', state: 'NE' },
    { key: 'Tucson, Arizona, United States', state: 'AZ' },
    { key: 'Greater Tampa Bay Area', state: 'FL' },
    { key: 'San Diego County, California, United States', state: 'CA' },
    { key: 'Greater St. Louis', state: 'MO' },
    { key: 'Orange County, California, United States', state: 'CA' },
    { key: 'Charlotte Metro', state: 'SC' },
    { key: 'Colorado Springs, Colorado, United States', state: 'CO' },
    { key: 'Austin, Texas Metropolitan Area', state: 'TX' },
    { key: 'Birmingham, Alabama, United States', state: 'AL' },
    { key: 'Scottsdale, Arizona, United States', state: 'AZ' },
    { key: 'Irvine, California, United States', state: 'CA' },
    { key: 'Memphis, Tennessee, United States', state: 'TN' },
    { key: 'Oklahoma City, Oklahoma, United States', state: 'OK' },
    { key: 'West Palm Beach, Florida, United States', state: 'FL' },
    { key: 'Raleigh-Durham-Chapel Hill Area', state: 'NC' },
    { key: 'Queens County, New York, United States', state: 'NY' },
    { key: 'Madison, Wisconsin, United States', state: 'MI' },
    { key: 'Portland, Oregon Metropolitan Area', state: 'OR' },
    { key: 'Bronx, New York, United States', state: 'NY' },
    { key: 'Rochester, New York, United States', state: 'NY' },
    { key: 'Buffalo, New York, United States', state: 'NY' },
    { key: 'Nashville Metropolitan Area', state: 'TN' },
    { key: 'Grand Rapids, Michigan, United States', state: 'MI' },
    { key: 'Greater Orlando', state: 'FL' },
    { key: 'Greater Cleveland', state: 'OH' },
    { key: 'Columbus, Ohio Metropolitan Area', state: 'OH' },
    { key: 'Plano, Texas, United States', state: 'TX' },
    { key: 'Albuquerque, New Mexico, United States', state: 'NM' },
    { key: 'Greater Pittsburgh Region', state: 'PA' },
    { key: 'Virginia Beach, Virginia, United States', state: 'VA' },
    { key: 'Oakland, California, United States', state: 'CA' },
    { key: 'Salt Lake City Metropolitan Area', state: 'UT' },
    { key: 'New Orleans, Louisiana, United States', state: 'LA' },
    { key: 'Kansas City Metropolitan Area', state: 'MO' },
    { key: 'Dayton, Ohio, United States', state: 'OH' },
    { key: 'Tulsa, Oklahoma, United States', state: 'OK' },
    { key: 'Arlington, Virginia, United States', state: 'VA' },
    { key: 'Knoxville, Tennessee, United States', state: 'TN' },
    { key: 'Greater Indianapolis', state: 'IN' },
    { key: 'Cincinnati Metropolitan Area', state: 'OH' },
    { key: 'Spring, Texas, United States', state: 'TX' },
    { key: 'Durham, North Carolina, United States', state: 'NC' },
    { key: 'Detroit, Michigan, United States', state: 'MI' },
    { key: 'Mesa, Arizona, United States', state: 'AZ' },
    { key: 'Greater Milwaukee', state: 'WI' },
    { key: 'Littleton, Colorado, United States', state: 'CO' },
    { key: 'Long Beach, California, United States', state: 'CA' },
    { key: 'St Petersburg, Florida, United States', state: 'FL' },
    { key: 'Marietta, Georgia, United States', state: 'GA' },
    { key: 'Greater New York City Area', state: 'NY' },
    { key: 'Baton Rouge, Louisiana, United States', state: 'LA' },
    { key: 'San Antonio, Texas Metropolitan Area', state: 'TX' },
    { key: 'Greensboro, North Carolina, United States', state: 'NC' },
    { key: 'Baltimore City County, Maryland, United States', state: 'MD' },
    { key: 'Hollywood, Florida, United States', state: 'FL' },
    { key: 'Boise, Idaho, United States', state: 'ID' },
    { key: 'Ann Arbor, Michigan, United States', state: 'MI' },
    { key: 'Greater Sacramento', state: 'CA' },
    { key: 'Aurora, Colorado, United States', state: 'CO' },
    { key: 'Boca Raton, Florida, United States', state: 'FL' },
    { key: 'Alpharetta, Georgia, United States', state: 'GA' },
    { key: 'Cambridge, Massachusetts, United States', state: 'MA' },
    { key: 'Fort Collins, Colorado, United States', state: 'CO' },
    { key: 'Lexington, Kentucky, United States', state: 'KY' },
    { key: 'Wichita, Kansas, United States', state: 'KS' },
    { key: 'El Paso, Texas, United States', state: 'TX' },
    { key: 'Overland Park, Kansas, United States', state: 'KS' },
    { key: 'Pompano Beach, Florida, United States', state: 'FL' },
    { key: 'New Alexandria, Virginia, United States', state: 'VA' },
    { key: 'Boulder, Colorado, United States', state: 'CO' },
    { key: 'Honolulu County, Hawaii, United States', state: 'HI' },
    { key: 'Arlington, Texas, United States', state: 'TX' },
    { key: 'Charleston, South Carolina, United States', state: 'SC' },
    { key: 'Tempe, Arizona, United States', state: 'AZ' },
    { key: 'Naples, Florida, United States', state: 'FL' },
    { key: 'Bellevue, Washington, United States', state: 'WA' },
    { key: 'Greenville, South Carolina, United States', state: 'SC' },
    { key: 'Chandler, Arizona, United States', state: 'AZ' },
    { key: 'Mountain View, California, United States', state: 'CA' },
    { key: 'Spokane, Washington, United States', state: 'WA' },
    { key: 'Las Vegas Metropolitan Area', state: 'NV' },
    { key: 'Lincoln, Nebraska, United States', state: 'NE' },
    { key: 'Tallahassee, Florida, United States', state: 'FL' },
    { key: 'Tacoma, Washington, United States', state: 'WA' },
    { key: 'Katy, Texas, United States', state: 'TX' },
    { key: 'Fresno, California, United States', state: 'CA' },
    { key: 'Reno, Nevada, United States', state: 'NV' },
    { key: 'Bakersfield, California, United States', state: 'CA' },
    { key: 'Lexington County, South Carolina, United States', state: 'SC' },
    { key: 'Irving, Texas, United States', state: 'TX' },
    { key: 'Sarasota, Florida, United States', state: 'FL' },
    { key: 'Fort Wayne, Indiana, United States', state: 'IN' },
    { key: 'Fremont, California, United States', state: 'CA' },
    { key: 'Santa Clara, California, United States', state: 'CA' },
    { key: 'Sunnyvale, California, United States', state: 'CA' },
    { key: 'Wilmington, Delaware, United States', state: 'DE' },
    { key: 'Staten Island, New York, United States', state: 'NY' },
    { key: 'Metro Jacksonville', state: 'FL' },
    { key: 'Silver Spring, Maryland, United States', state: 'MD' },
    { key: 'Winston-Salem, North Carolina, United States', state: 'NC' },
    { key: 'Riverside, California, United States', state: 'CA' },
    { key: 'Huntsville, Alabama, United States', state: 'AL' },
    { key: 'Jersey City, New Jersey, United States', state: 'NJ' },
    { key: 'Henderson, Nevada, United States', state: 'NV' },
    { key: 'Little Rock, Arkansas, United States', state: 'AR' },
    { key: 'Cary, North Carolina, United States', state: 'NC' },
    { key: 'Eugene, Oregon, United States', state: 'OR' },
    { key: 'Anchorage, Alaska, United States', state: 'AK' },
    { key: 'Greater Richmond Region', state: 'VA' },
    { key: 'Fort Myers, Florida, United States', state: 'FL' },
    { key: 'Akron, Ohio, United States', state: 'OH' },
    { key: 'Chattanooga, Tennessee, United States', state: 'TN' },
    { key: 'Wilmington, North Carolina, United States', state: 'NC' },
    { key: 'Melbourne, Florida, United States', state: 'FL' },
    { key: 'Berkeley, California, United States', state: 'CA' },
    { key: 'Greater Hartford', state: 'IL' },
    { key: 'Des Moines, Iowa, United States', state: 'IA' },
    { key: 'Anaheim, California, United States', state: 'CA' },
    { key: 'Gilbert, Arizona, United States', state: 'AZ' },
    { key: 'Gainesville, Florida, United States', state: 'FL' },
    { key: 'Naperville, Illinois, United States', state: 'IL' },
    { key: 'Providence, Rhode Island, United States', state: 'RI' },
    { key: 'Savannah, Georgia, United States', state: 'GA' },
    { key: 'Santa Monica, California, United States', state: 'CA' },
    { key: 'Huntington Beach, California, United States', state: 'CA' },
    { key: 'Frisco, Texas, United States', state: 'TX' },
    { key: 'Allentown, Pennsylvania, United States', state: 'PA' },
    { key: 'Oklahoma City Metropolitan Area', state: 'OK' },
    { key: 'Redmond, Washington, United States', state: 'WA' },
    { key: 'Provo, Utah, United States', state: 'UT' },
    { key: 'Rockville, Maryland, United States', state: 'MD' },
  ];

  JobFunctions = [
    'Banking and Wealth Management',
    'Business Management',
    'Consulting',
    'Education',
    'Engineering',
    'Finance and Administration',
    'Healthcare',
    'Human Resources',
    'Information Technology',
    'Legal',
    'Marketing and Product',
    'Operations',
    'Other',
    'Program and Project Management',
    'Publishing, Editorial and Reporting',
    'Quality',
    'Real Estate',
    'Risk, Safety, Compliance',
    'Sales and Support',
  ];
}
