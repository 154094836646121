<!-- Flex container with full height to grow with content -->
<div class="tw-flex tw-flex-col tw-h-full">
  <div class="dialog-header tw-flex tw-justify-between tw-items-center tw-flex-wrap">
    <h2
      mat-dialog-title
      class="tw-font-semibold tw-text-gray-800 tw-mb-0 tw-flex-1 tw-break-words tw-text-wrap">
      Configure New AWS S3 Bucket Destination
    </h2>
    <button
      mat-icon-button
      mat-dialog-close
      aria-label="Close dialog"
      class="tw-text-gray-500 tw-bg-gray-100 hover:tw-text-gray-800 hover:tw-bg-gray-200 tw-w-8 tw-h-8 tw-leading-5 tw-mr-3">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="modal-content tw-flex-1 tw-overflow-auto">
    <app-custom-stepper [steps]="stepperSteps" [activeStep]="activeStepperStep">
    </app-custom-stepper>

    <!-- Card 1: Choice 1: User S3 bucket OR LDT S3 bucket, Step 1 -->
    <div
      *ngIf="!showChoice1 && !showChoice2 && showInit"
      class="tw-flex tw-flex-col tw-max-w-[800px] tw-mt-8">
      <div class="tw-flex tw-items-stretch tw-gap-4 lg:tw-gap-8">
        <div class="tw-flex tw-flex-col tw-py-3 tw-px-0 tw-flex-1">
          <button
            type="button"
            class="tw-text-white tw-bg-primary_purple-500 focus:tw-ring-4 focus:tw-ring-blue-300 hover:tw-ring-blue-300 tw-rounded-md tw-text-center tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-3 lg:tw-px-5 lg:tw-py-2.5"
            (click)="handleChoice1()">
            <img
              src="../../../../assets/icons/s3-bucket.svg"
              alt="S3 Bucket"
              class="tw-w-8 lg:tw-w-10" />
            <span
              class="tw-inline-block tw-text-sm lg:tw-text-base tw-font-semibold tw-ml-1 lg:tw-ml-3">
              Your S3 Bucket
            </span>
          </button>

          <div class="tw-font-semibold tw-p-2">
            Grant Live Data access to send data to your S3 bucket.
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-py-3 tw-px-0 tw-flex-1">
          <button
            type="button"
            class="tw-text-white tw-bg-primary_purple-500 focus:tw-ring-4 focus:tw-ring-blue-300 hover:tw-ring-blue-300 tw-rounded-md tw-text-center tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-3 lg:tw-px-5 lg:tw-py-2.5"
            (click)="handleChoice2()">
            <img
              src="../../../../assets/icons/s3-bucket.svg"
              alt="S3 Bucket"
              class="tw-w-8 lg:tw-w-10" />
            <span
              class="tw-inline-block tw-text-sm lg:tw-text-base tw-font-semibold tw-ml-1 lg:tw-ml-3">
              Live Data S3 Bucket
            </span>
          </button>

          <div class="tw-font-semibold tw-p-2">
            Access the data in Live Data's bucket using your own AWS role.
          </div>
        </div>
      </div>
    </div>

    <!-- Card 2: Show Choice 1A and Choice 1B options, Step 1 -->
    <div *ngIf="showChoice1 && !showChoice2" class="tw-flex tw-flex-col tw-max-w-[800px] tw-mt-8">
      <div class="tw-flex tw-items-stretch tw-gap-4 lg:tw-gap-8">
        <!-- Choice 1A: User S3 bucket > Grant Access to LDT Role -->
        <div class="tw-flex tw-flex-col tw-py-3 tw-px-0 tw-flex-1">
          <button
            type="button"
            class="tw-text-white tw-bg-primary_purple-500 focus:tw-ring-4 focus:tw-ring-blue-300 hover:tw-ring-blue-300 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-h-[70px] tw-py-1 tw-px-3 lg:tw-px-5 lg:tw-py-2.5"
            (click)="handleChoice1A()">
            <mat-icon fontIcon="vpn_key_outline" class="tw-w-8 lg:tw-w-10"></mat-icon>
            <span
              class="tw-inline-block tw-text-sm lg:tw-text-base tw-font-semibold tw-ml-1 lg:tw-ml-3">
              Grant Access to Live Data Role
            </span>
          </button>

          <div class="tw-font-semibold tw-p-2">Permit a Live Data role access to your bucket.</div>
        </div>

        <!-- Choice 1B: User S3 bucket > Create Cross-account role & allow access to LDT -->
        <div class="tw-flex tw-flex-col tw-py-3 tw-px-0 tw-flex-1">
          <button
            type="button"
            class="tw-text-white tw-bg-primary_purple-500 focus:tw-ring-4 focus:tw-ring-blue-300 hover:tw-ring-blue-300 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-h-[70px] tw-py-1 tw-px-3 lg:tw-px-5 lg:tw-py-2.5"
            (click)="handleChoice1B()">
            <mat-icon fontIcon="manage_accounts" class="tw-w-8 lg:tw-w-10"></mat-icon>
            <span
              class="tw-inline-block tw-text-sm lg:tw-text-base tw-font-semibold tw-ml-1 lg:tw-ml-3">
              <span class="tw-break-words tw-text-wrap"
                >Create a Cross-Account Role for Live Data to Use</span
              >
            </span>
          </button>

          <div class="tw-font-semibold tw-p-2">
            Create a cross-account role in your account and permit Live Data to assume it.
          </div>
        </div>
      </div>
    </div>

    <!-- Card 3: Show Choice 1A form, Step 2 -->
    <!-- [ID 3] Internal Role Customer S3 Bucket -->
    <div *ngIf="showChoice1A && !showChoice1B" class="tw-flex tw-flex-col tw-mt-8">
      <form #form1A="ngForm" class="tw-flex tw-flex-col">
        <p class="tw-font-semibold">
          Grant the Live Data role access to a bucket/path of your choice.
        </p>

        <p>
          This approach requires configuration steps in your AWS account. For more,
          <a
            href="https://livedata.readme.io/docs/aws-s3#grant-a-live-data-aws-role-direct-access-to-a-bucket-in-your-aws-account"
            class="tw-inline-flex tw-items-center tw-font-medium tw-text-blue-600 hover:tw-underline"
            target="_blank">
            see the detailed instructions.
            <mat-icon class="material-icons-outlined tw-ml-1 tw-text-sm"> open_in_new </mat-icon>
          </a>
        </p>

        <mat-form-field appearance="outline">
          <mat-label>Destination Display Name</mat-label>
          <input
            matInput
            [(ngModel)]="form1AName"
            placeholder="Display name for this data destination"
            name="name"
            required />
          <mat-error *ngIf="form1A.controls.name?.errors?.required">Name is required.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Your S3 Bucket Identifier</mat-label>
          <input
            matInput
            [(ngModel)]="form1ABucketIdentifier"
            placeholder="Bucket ARN, URI, or name"
            name="bucket_identifier"
            required />
          <mat-error *ngIf="form1A.controls.bucket_identifier?.errors?.required">
            Bucket Identifier is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Object prefix</mat-label>
          <input
            matInput
            [(ngModel)]="form1ABucketKey"
            placeholder="All objects placed here will have this prefix"
            name="bucket_key" />
        </mat-form-field>
      </form>
    </div>

    <!-- Card 4: Show Choice 1B form, Step 2-->
    <!-- [ID 2] Customer Cross-account Role Customer S3 Bucket  -->
    <div *ngIf="!showChoice1A && showChoice1B" class="tw-flex tw-flex-col tw-mt-8">
      <form #form1B="ngForm" class="tw-flex tw-flex-col tw-mt-5">
        <p class="tw-mt-0 tw-mb-2 tw-font-semibold">
          Create a cross-account role to permit Live Data access to your bucket.
        </p>

        <p>
          This approach requires configuration steps in your AWS account. For more,
          <a
            href="https://livedata.readme.io/docs/aws-s3#create-a-cross-account-aws-role-in-your-account-and-permit-live-data-to-assume-the-role"
            class="tw-inline-flex tw-items-center tw-font-medium tw-text-blue-600 hover:tw-underline"
            target="_blank">
            see the detailed instructions.<mat-icon
              class="material-icons-outlined tw-ml-1 tw-text-sm">
              open_in_new
            </mat-icon>
          </a>
        </p>

        <mat-form-field appearance="outline" class="compact-mat-form-field">
          <mat-label>Destination Display Name</mat-label>
          <input
            matInput
            [(ngModel)]="form1BName"
            name="name"
            placeholder="Display name for this data destination"
            required />
          <mat-error *ngIf="form1B.controls.name?.errors?.required">Name is required.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Your S3 Bucket Identifier</mat-label>
          <input
            matInput
            [(ngModel)]="form1BBucketIdentifier"
            placeholder="Bucket ARN, URI, or name"
            name="bucket_identifier"
            required />
          <mat-error *ngIf="form1B.controls.bucket_identifier?.errors?.required">
            Bucket Identifier is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Object prefix</mat-label>
          <input
            matInput
            [(ngModel)]="form1BBucketKey"
            placeholder="All objects placed here will have this prefix"
            name="bucket_key" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Role ARN</mat-label>
          <input
            matInput
            [(ngModel)]="form1BRoleARN"
            placeholder="E.g., arn:aws:iam::123456789012:role/ExampleRole"
            name="role_arn"
            pattern="arn:aws:iam::[0-9]{12}:role/[a-zA-Z0-9\-_.]{3,64}"
            required />
          <mat-error *ngIf="form1B.controls.role_arn?.errors?.required">
            Role ARN is required.
          </mat-error>
          <mat-error *ngIf="form1B.controls.role_arn?.errors?.pattern">
            Please enter a valid ARN (e.g., arn:aws:iam::123456789012:role/ExampleRole).
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>External ID</mat-label>
          <input
            matInput
            [(ngModel)]="form1BExternalID"
            placeholder="E.g., companyX-access-12345"
            name="external_id"
            required />
          <mat-error *ngIf="form1B.controls.external_id?.errors?.required">
            External ID is required.
          </mat-error>
        </mat-form-field>
      </form>
    </div>

    <!-- Card 5: Show Choice 2: Form 2A, Step 2-->
    <!-- [ID 1] LDT S3 Bucket Customer Access  -->
    <div *ngIf="!showChoice1 && showChoice2" class="tw-flex tw-flex-col tw-mt-8">
      <form #form2A="ngForm" class="tw-flex tw-flex-col tw-mt-5">
        <p class="tw-mt-0 tw-mb-2 tw-font-semibold">
          Access Live Data's bucket directly using your own AWS role.
        </p>

        <p>
          For more information,
          <a
            href="https://livedata.readme.io/docs/aws-s3#use-your-aws-role-to-access-data-in-a-bucket-owned-by-live-data"
            class="tw-inline-flex tw-items-center tw-font-medium tw-text-blue-600 hover:tw-underline"
            target="_blank">
            see the detailed instructions.<mat-icon
              class="material-icons-outlined tw-ml-1 tw-text-sm">
              open_in_new
            </mat-icon>
          </a>
        </p>

        <mat-form-field appearance="outline">
          <mat-label>Destination Display Name</mat-label>
          <input
            matInput
            [(ngModel)]="form2AName"
            name="name"
            placeholder="Display name for this data destination"
            required />
          <mat-error *ngIf="form2A.controls.name?.errors?.required">Name is required.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>ARN of your IAM Role</mat-label>
          <input
            matInput
            [(ngModel)]="form2ARoleARN"
            name="role_arn"
            #roleArnInput="ngModel"
            placeholder="E.g., arn:aws:iam::123456789012:role/ExampleRole"
            required
            pattern="arn:aws:iam::[0-9]{12}:role/[a-zA-Z0-9\-_.]{3,64}" />
          <mat-error *ngIf="form2A.controls.role_arn?.errors?.required">ARN is required.</mat-error>
          <mat-error *ngIf="form2A.controls.role_arn?.errors?.pattern">
            Please enter a valid ARN (e.g., arn:aws:iam::123456789012:role/ExampleRole).
          </mat-error>
          <mat-error *ngIf="roleArnInput.errors?.serverError">
            {{ roleArnInput.errors?.serverError }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>

    <!-- Success message for all choices, if submission successful, Step 3 -->
    <div *ngIf="successView" class="tw-flex tw-flex-col tw-mt-8">
      <p *ngIf="newIntegration" class="tw-mt-5 tw-mb-2">Setup is complete!</p>

      <p class="tw-mb-2 tw-flex tw-flex-col">
        <span class="tw-font-semibold tw-mr-2">Destination Name: </span>
        <span class="tw-inline-block code-font-direct">{{createdDestination.name}}</span>
      </p>

      <!-- type_id = 1 -->
      <div *ngIf="createdDestination.destination_type_id === 1">
        <app-config-info
          [itemName]="'Role ARN'"
          [itemValue]="createdDestination.config.role_arn"></app-config-info>

        <app-config-info
          [itemName]="'S3 Bucket Location'"
          [itemValue]="getFormattedS3Uri(createdDestination.id)"></app-config-info>

        <app-config-info
          [itemName]="'Access Point ARN'"
          [itemValue]="getFormattedAccessPointARN(createdDestination.id)"></app-config-info>
      </div>

      <!-- type_id = 2 -->
      <div *ngIf="createdDestination.destination_type_id === 2" class="tw-mb-2 tw-flex tw-flex-col">
        <app-config-info
          [itemName]="'Bucket Identifier'"
          [itemValue]="createdDestination.config.bucket_identifier"></app-config-info>

        <app-config-info
          [itemName]="'Role ARN'"
          [itemValue]="createdDestination.config.role_arn"></app-config-info>

        <app-config-info
          [itemName]="'External ID'"
          [itemValue]="createdDestination.config.external_id"></app-config-info>
      </div>

      <!-- type_id = 3 -->
      <div *ngIf="createdDestination.destination_type_id === 3" class="tw-mb-2 tw-flex tw-flex-col">
        <app-config-info
          [itemName]="'Bucket Identifier'"
          [itemValue]="createdDestination.config.bucket_identifier"></app-config-info>
      </div>

      <div
        *ngIf="createdDestination.destination_type_id !== 1 && !creationTimeout"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-52">
        <div class="tw-mb-5">
          <app-test-status-badge
            [testStatus]="createdDestination.status"
            [isLoading]="isLoading"
            [showTimeStamp]="showTimeStamp"></app-test-status-badge>
        </div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="isLoading"
          (click)="testDestinationStatus(createdDestination)">
          Test Integration
        </button>
      </div>

      <div
        *ngIf="creationTimeout"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-52">
        <div class="tw-mb-5">
          <app-test-status-badge
            testStatusLabel="Configuring"
            isLoading="true"
            [showTimeStamp]="false"></app-test-status-badge>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="modal-footer tw-px-6 tw-py-4 tw-mt-auto">
    <button *ngIf="!successView" mat-raised-button class="tw-mx-0" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      *ngIf="showChoice1 || showChoice2 || showChoice1A || showChoice1B"
      mat-raised-button
      class="tw-ml-3"
      (click)="goBack()"
      type="button">
      Go Back
    </button>
    <button *ngIf="successView" mat-raised-button class="tw-ml-4" (click)="closeDialog()">
      Close
    </button>

    <button
      *ngIf="showChoice1A || showChoice1B || showChoice2"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!isFormValid() || isLoading || isSubmitting"
      class="tw-ml-auto"
      (click)="submitActiveForm()">
      Save
      <span *ngIf="isLoading" class="spinner tw-relative tw-mx-5"></span>
    </button>
  </mat-dialog-actions>
</div>
